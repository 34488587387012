<template>
  <form @submit.prevent="addClient">
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">
          Add client
        </p>
      </header>

      <section class="modal-card-body">
        <p class="has-margin-bottom-100">
          To add a client simply enter their email address below and we'll send
          them a white-labelled welcome email, including account login
          instructions.
        </p>

        <div>
          <b-field label="Client email">
            <b-input
              ref="email"
              v-model="form.email"
              :disabled="processing"
              :readonly="readonly"
              required
              type="email"
              placeholder="eg. jane.doe@gmail.com"
            />
          </b-field>
        </div>
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="processing"
          type="button"
          class="button is-light"
          tabindex="-1"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          v-if="!readonly"
          :class="{ 'is-loading': processing }"
          :disabled="formIsDisabled"
          type="submit"
          class="button is-success"
        >
          Add client
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "AddResellerClientModal",
  props: {
    resellerId: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: false,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      processing: false,
      form: {
        email: this.email
      }
    };
  },
  computed: {
    formIsValid() {
      if (!this.form.email) return false;
      return true;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    }
  },
  methods: {
    addClient() {
      if (!this.formIsDisabled) {
        this.processing = true;
        this.$store
          .dispatch("auth/registerResellerUser", {
            resellerId: this.resellerId,
            email: this.form.email
          })
          .then(result => {
            this.$emit("created");
            if (result) {
              this.processing = false;
              this.$toast.open({
                message: result.message,
                position: "is-bottom",
                queue: false
              });
            }
          })
          .catch(error => {
            this.processing = false;
            this.$toast.open({
              message: error.message,
              position: "is-bottom",
              queue: false,
              type: "is-danger"
            });
          });
      }
    }
  }
};
</script>
